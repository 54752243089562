<template>
  <div class="faq_list">
    <div class="title flex ai-c jc-sb">
      <p>Preguntas frecuentes</p>
      <!-- <img src="@/assets/images/loanImg/icon_faq.png"> -->
    </div>
    <div class="flex">
      <ul class="hotel-li flex fd-c ai-c">
        <li
          @click="goAnchor(item.link,index)"
          v-for="(item,index) in navData.list"
          :key="index"
          :class="navData.index === index ? 'select-item under-line': 'select-item'"
        >
          <a>{{ item.name }}</a>
        </li>
      </ul>
      <div id="containerList">
        <div id="#selector1" class="container">
          <h3 class="tit" :class="navData.index === 0 ? 'select-item' : ''">Acerca de los préstamos</h3>
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item v-for="(item, index) in faqList" :id="item.id" :key="index" :title="item.title" :name="item.name">
              <div v-html="item.dec" />
            </el-collapse-item>
          </el-collapse>
        </div>
        <div id="#selector2" class="container">
          <h3 class="tit" :class="navData.index === 1 ? 'select-item' : ''">Sobre el reembolso</h3>
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item v-for="(item, index) in faqList2" :id="item.id" :key="index" :title="item.title" :name="item.name">
              <div v-html="item.dec" />
            </el-collapse-item>
          </el-collapse>
        </div>
        <div id="#selector3" class="container">
          <h3 class="tit" :class="navData.index === 2 ? 'select-item' : ''">Otros problemas</h3>
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item v-for="(item, index) in faqList3" :id="item.id" :key="index" :title="item.title" :name="item.name">
              <div v-html="item.dec" />
            </el-collapse-item>
          </el-collapse>
        </div>
        <!-- <div id="#selector4" class="container">
          <h3 class="tit" :class="navData.index === 3 ? 'select-item' : ''">Other Questions</h3>
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item v-for="(item, index) in faqList4" :id="item.id" :key="index" :title="item.title" :name="item.name">
              <div v-html="item.dec" />
            </el-collapse-item>
          </el-collapse>
        </div> -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1'],
      faqList: [
        {
          name: '1',
          id: 'question-1',
          title: '1.Monto de préstamo',
          dec: 'MonedaCome ofrece préstamos hasta un máximo de 50,000 pesos, cuyo crédito exacto es evaluado por el sistema con base en la información de su solicitud.'
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.¿Cómo solicitar un préstamo?',
          dec: 'Desde Google Playstore puede descargar MonedaCome, regístrese con su número celular e inicie sesión para solicitar un préstamo.'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.¿Cómo obtener el monto del préstamo?',
          dec: 'Inicie sesión en la aplicación. Después de ingresar la información personal solicitada de acuerdo al proceso, evaluaremos su solicitud después de completarla. Esto determinará si se le concederá una línea de crédito. Para asegurarse de poder obtener el monto del préstamo sin problemas, le recomendamos verificar la vigencia de toda su información.'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4.Cometí un error al completar mi perfil, ¿puedo modificar mi perfil?',
          dec: 'Para la conservación y protección de datos personales, Después de la presentación exitosa de sus datos no se admite la modificación. Verifica cuidadosamente la información ingresada antes de enviar.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5.¿Cuánto tiempo dura el proceso de aprobación del préstamo?',
          dec: '1. Después de rellenar todos sus datos personales, la mayoría de las aprobaciones de los créditos de los préstamos se procesarán en 3 minutos. <br> 2. La mayoría de las aprobaciones del depósito del préstamo se completan en 1-2 horas, con un máximo de 24 horas. Si no ha recibido su préstamo después de 24 horas, póngase en contacto con servicio de atención al cliente.'
        },
        {
          name: '6',
          id: 'question-6',
          title: '6.¿Como recibir mi préstamo?',
          dec: 'Si su solicitud está aprobada, normalmente el depósito se tardará unas 2-3 horas en llegar con éxito a su cuenta. Las transferencias bancarias pueden retrasarse debido a problemas de la red del sistema, así que no se preocupe demasiado. Si el pago es exitoso, MonedaCome le enviará una notificación, así que sea paciente. MonedaCome solo empezará a calcular los intereses a partir del día siguiente a la realización del depósito. Si no ha recibido el depósito más de 24 horas, póngase en contacto con el servicio de atención al cliente.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7.¿Se puede incrementar el monto del préstamo?',
          dec: 'MonedaCome evalúa regularmente el crédito del usuario de forma razonable y le ofrece un límite de préstamo adecuado a su solvencia. Le recomendamos que mantenga un buen crédito de préstamo haciendo sus pagos a tiempo, ya que repetir un buen crédito le ayudará a obtener un crédito de préstamo más alto.'
        },
        {
          name: '8',
          id: 'question-8',
          title: '8.Si al tomar el préstamo este no fue aprobado, ¿puedo aplicar de nuevo? ',
          dec: 'Si su solicitud de préstamo no es aprobada, no cumple con los requisitos para solicitar un préstamo de MonedaCome en este momento. Se le enviará la notificación si posteriormente cumple los requisitos de la solicitud y podrá volver a solicitarlo.'
        },
        {
          name: '9',
          id: 'question-9',
          title: '9.¿Puedo cancelar mi solicitud de préstamo?',
          dec: 'Una vez enviada la solicitud de préstamo, se procesará automáticamente y no se podrá cancelar.  <br> Si cuenta con alguna duda, comuníquese con el servicio de atención al cliente.'
        },
        {
          name: '10',
          id: 'question-10',
          title: '10.¿Por qué no se ha aprobado mi solicitud de pedir un nuevo préstamo? ',
          dec: 'MonedaCome evalúa cada préstamo de forma razonable, basándose en el historial de préstamos de cada usuario, su capacidad de reembolso y su crédito. Le recomendamos que mantenga un buen crédito devolviendo su préstamo a tiempo. Si su solicitud de préstamo no ha sido aprobada, es que aún no cumple con los requisitos para obtener un préstamo de MonedaCome. <br> Se le enviará la notificación si posteriormente cumple los requisitos de la solicitud y podrá volver a solicitarlo.'
        }
      ],
      faqList2: [
        {
          name: '1',
          id: 'question-1',
          title: '1.¿Cómo puedo reembolsar mi préstamo?',
          dec: 'Entre en la página de reembolso de MonedaCome y haga clic en "Pagar Ahora" para ser llevado a la página del Número de Pago. El importe del reembolso que se muestra en la página de inicio es el importe total adeudado en su factura más reciente. Si quiere ver todas sus facturas pendientes, puede hacer clic en "Detalles de la factura" para verlas todas. Puede realizar una transferencia vía SPEI desde su app de banco móvil o visitar un punto de pago Paycash cercano y pagar en efectivo. Encuentre un establecimiento de pago paycash aquí: https://www.paycashglobal.com/buscador.php <br> Si cuenta con algún inconveniente con el pago, comuníquese con el servicio de atención al cliente.'
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.¿Por qué después del pago de mi préstamo el estado del mismo no está actualizado?',
          dec: 'El tiempo de reembolso suele ser de unas 2-3 horas. Las transferencias bancarias pueden retrasarse debido a problmas de la red del sistema. Si el reembolso es exitoso, MonedaCome le enviará una notificación, por favor sea paciente. Si no recibe una notificación de reembolso con éxito más de 24 horas después del momento del reembolso, póngase en contacto con nuestro servicio de atención al cliente por correo electrónico para explicar la situación y adjuntar una evidencia de reembolso. Después de la verificación con el banco, el servicio de atención al cliente cambiará su estado de reembolso en la aplicación y si tiene cuotas atrasadas, puede solicitar un alivio.'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.¿Puedo obtener un reembolso si pagué de más?',
          dec: '1. Si ha realizado demasiados reembolsos y se han liquidado todos sus préstamos, póngase en contacto con nuestro servicio de atención al cliente para solicitarlo.  <br> 2. Si tiene una factura pendiente posterior, el pago en exceso pasará automáticamente a la siguiente factura y no se podrá reembolsar ese importe.'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4.¿Se pueden eximir de algunos cargos si pago antes o en la fecha de vencimiento?',
          dec: 'Puede devolver el préstamo antes de la fecha de vencimiento, pero tendrá que pagar la comisión de tramitación. Una vez que haya liquidado su préstamo actual, puede solicitar un nuevo. Si paga a tiempo, aumentará su crédto de préstamo y podrá optar a mejores productos de préstamo en el futuro.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5.¿Puedo aplazar el pago?',
          dec: 'Actualmente no con ningún tipo de prorroga o convenio de pago, un atraso podría afectar su historial de crédito con  nosotros. Se cobrará un cargo por pago atrasado. Se recomienda pagar a tiempo para evitar multas.'
        },
        {
          name: '6',
          id: 'question-6',
          title: '6.¿Puedo cambiar la fecha de reembolso?',
          dec: 'La fecha de reembolso del producto a parcialidades no se puede cambiar, por favor sígala en la aplicación.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7.¿Qué hago si no puedo reembolsar a tiempo?',
          dec: 'Si se retrasa en el pago de su factura, se le cobrará una comisión por retraso y esto afectará a su crédito y tendrá un efecto negativo en su próximo préstamo.'
        }
      ],
      faqList3: [
        {
          name: '1',
          id: 'question-1',
          title: '1.¿Cómo me registro en MonedaCome?',
          dec: 'Deberá introducir su número de teléfono móvil, hacer clic para obtener código de verificación y, a continuación, introducir el código de verificación que reciba completar su registro.'
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.¿Qué hago si no recibo el código de verificación?',
          dec: 'Ofrecemos dos formas de verificar, el código de verificación por SMS y por llamada de voz. Asegúrese de que su teléfono funciona bien y de que la señal es estable. Puede haber algunos retrasos cuando el servidor de su operador esté ocupado. Si no recibe el código de verificación, inténtelo más tarde.'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.¿No se puede hacer reconocimiento facial?',
          dec: 'Para la verificación de la cara, en primer lugar, asegúrese de que ha activado los permisos de la cámara, si no, por favor, actívelo en la configuración de su teléfono móvil. Cuando realice el reconocimiento facial, intente elegir un entorno luminoso y asegúrese de que el dispositivo utilizado está en condiciones buenas y en un buen entorno de red.'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4.¿No puedo iniciar sesión en la aplicación/la aplicación está atascada/no puedo acceder a mi cuenta?',
          dec: 'Asegúrese de que su número de móvil y su código de verificación es correcta.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5.¿Cómo puedo cambiar mi nombre?',
          dec: 'Considerando la seguridad de su información, no se puede realizar los cambios del nombre en este momento. Sin embargo, para realizar una solicitud de préstamo, deberá asegurarse de que su nombre coincida con su nombre completo en su documento de identificación (INE / IFE).'
        },
        {
          name: '6',
          id: 'question-6',
          title: '6.¿Cómo puedo cambiar mi número de teléfono?',
          dec: 'Considerando la seguridad de su información, no se puede realizar los cambios en su número de teléfono en este momento.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7.¿Cómo puedo cambiar mi tarjeta bancaria?',
          dec: 'Por seguridad de la cuenta, no proporcionamos modificaciones directamente. Para cambiar su tarjeta bancaria, puede ponerse en contacto con el servicio de atención al cliente y proporcionar los documentos que le solicite.'
        },
        {
          name: '8',
          id: 'question-8',
          title: '8.¿Cómo puedo presentar una queja?',
          dec: 'Puede ponerse en contacto con nuestro servicio de atención al cliente, explicar el motivo de su reclamación y aportar las pruebas oportunas. Puedes compartir información sobre la orden, como el nombre del prestatario, la fecha del préstamo, la cantidad prestada, etc. Haremos todo lo posible para ayudarle a resolver el problema.'
        }
      ],
      faqList4: [
        {
          name: '1',
          id: 'question-1',
          title: "1.Q: Why can't I submit when I fill in how much information?",
          dec: 'A:When you experience any technical issues with the PesoGo VIP app, please fill in the feedback.'
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.Q: Can I change my telephone number and ID card information?',
          dec: 'A:To ensure the authenticity and security of identity information, we are currently unable to facilitate changes to telephone numbers and ID cards.'
        }
      ],
      navData: {
        index: 0,
        list: [
          {
            name: 'Acerca de los préstamos',
            link: '#selector1'
          },
          {
            name: 'Sobre el reembolso',
            link: '#selector2'
          },
          {
            name: 'Otros problemas',
            link: '#selector3'
          }
          // {
          //   name: 'Other Questions',
          //   link: '#selector4'
          // }
        ]
      }
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
    },
    goAnchor(selector, index) {
      this.navData.index = index
      const section = document.getElementById(selector)
      var element = document.getElementById('containerList')
      console.log(section, element)
      if (section && element) {
        const children = element.children
        let topOffset = 0
        for (let i = 0; i < children.length; i++) {
          if (children[i] === section) {
            break
          }
          topOffset += children[i].offsetHeight
        }
        element.scrollTo({ top: topOffset, behavior: 'smooth' })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.faq_list{
  // min-width: 1200px;
  margin: 0 auto;
  .title{
    // background: linear-gradient(72deg, #1CB9A4 0%, #0DA7C5 100%);
    background: #214BC0;
    height: 150px;
    padding: 50px 0  50px calc((100% - 1200px) / 2);
    color: white !important;
    font-weight: bold;
    font-size: 30px;
    img{
      width: 350px;
      height: 140px;
      margin: 0;
    }
  }
  .nav-inner{

  }
  img{
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 550px;
  }
  #containerList{
    flex: 3.7;
    padding: 60px 100px;
    overflow: scroll;
    height: 80vh;
  }
  .container{
    // padding: 0 calc((100% - 1200px) / 2) 50px;
    margin-bottom: 40px;
    .tit{
      font-size: 25px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    .select-item{
      color: #204BC0 !important;
    }
  }
  .hotel-li {
    background:#F4F3F4;
    flex: 1;
    padding-top: 50px;
    li {
      width: 40%;
      font-size: 16px;
      text-align: left;
      line-height: 80px;
      white-space:nowrap;
      cursor: pointer;
      a{
        color: #333333;
        text-decoration: none;
      }
      &.under-line{
        color: $color-6E60F9;
        a{
          font-weight: bold;
          color: #204BC0;
          position: relative;
          &::after{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -15px;
            content: '';
            width: 100%;
            height: 3px;
            background: #204BC0;
          }
        }
      }
    }
  }
}
:deep(.el-collapse-item__header) {
  background: #EEEEEE;
  padding-left: 20px;
}
:deep(.el-collapse-item__content) {
  padding: 20px 480px 50px 20px;
}
:deep(.el-collapse-item__wrap){
  border: none;
}
@media only screen and (max-width: 665px){
  .faq_list{
    min-width: calc(100vw);
    padding: 0 !important;
      img{
        width:  100%;
        height: 150px;
        // margin: 10px auto;
      }
  }
  .title{
    height: 100px !important;
    img{
      width: 150px !important;
      height: 50px !important;
    }
    font-size: 14px !important;
    padding: 0 0 0 20px !important;
  }
  .hotel-li{
    li{
      width: 100% !important;
      font-size: 12px !important;
      padding: 0 10px;
    }
    padding-top: 30px !important;
  }
  #containerList{
    padding: 30px 10px !important;
  }
  .container{
    .tit{
      font-size: 16px !important;
    }
    margin-bottom:20px !important;
  }
  :deep(.el-collapse-item__header) {
    background: #EEEEEE;
    padding-left: 10px;
    font-size: 12px !important;
    line-height: 24px !important;
    height: auto !important;
    padding: 5px 0 0 12px;
    // height: 75px !important;
  }
  :deep(.el-collapse-item__content) {
    padding: 10px 10px !important;
    font-size: 12px !important;
  }
}
</style>
